import React from "react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.footerContent}>
        <p>Kontakta oss</p>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon className={styles.icon} icon="phone" />
          <a href="tel:073-1449331" title="Ring" className={styles.iconText}>
            073-1449331
          </a>
        </div>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon className={styles.icon} icon="envelope" />
          <a
            href="mailto:adam@dalarorentreprenad.com"
            title="Maila"
            className={styles.iconText}
          >
            adam@dalarorentreprenad.com
          </a>
        </div>
      </div>

      <div className={styles.footerContent}>
        <p>Leveransadress</p>
        <p>Lennheden 354</p>
        <p>78169 Borlänge</p>
      </div>

      <div className={styles.footerContent}>
        <p>Dala Rörentreprenad AB</p>
        <p>&copy; All Rights Reserved 2020</p>
        <p>Org. nr: 559269-4623</p>
        <p>Godkänd för F-skatt</p>
      </div>

      <div className={styles.footerContent}>
        <p>Sociala medier</p>
        <div className={styles.socialMediaWrapper}>
          <a href="https://www.facebook.com/dalarorentreprenad">
            <FontAwesomeIcon
              className={styles.icon}
              icon={["fab", "facebook-square"]}
              size="2x"
            />
          </a>
          <a href="https://www.instagram.com/dalarorentreprenad">
            <FontAwesomeIcon
              className={styles.icon}
              icon={["fab", "instagram"]}
              size="2x"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
