import React from "react";
import Header from "../../components/Header";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import About from "../../components/About";
import PreviousWork from "../../components/PreviousWork";
import background1 from "../../images/kran.jpg";
import background2 from "../../images/stege.jpg";
import { aboutSectionHeader1, aboutSectionText1 } from "./texts";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <About
        textPosition="left"
        background={background1}
        text={aboutSectionText1}
        header={aboutSectionHeader1}
        buttonText="Gå till Dala Rörservice"
      />
      <Contact />
      <About textPosition="none" background={background2} />
      {/* <PreviousWork /> */}
      <Footer />
    </div>
  );
};
export default LandingPage;
