import React from "react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactCard = ({ image, name, phone, email }) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className={styles.textWrapper}>
        <p className={styles.contactName}>{name}</p>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon className={styles.icon} icon="phone" />
          <a href={`tel:${phone}`} title="Ring" className={styles.iconText}>
            {phone}
          </a>
        </div>
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon className={styles.icon} icon="envelope" />
          <a href={`mailto:${email}`} title="Maila" className={styles.iconText}>
            {email}
          </a>
        </div>
      </div>
    </div>
  );
};
export default ContactCard;
