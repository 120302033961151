import React from "react";
import styles from "./styles.module.scss";
import ContactCard from "./ContactCard";
import sune from "../../images/profile.png";

const Contact = () => {
  return (
    <div className={styles.container}>
      <h1>Kontakt</h1>
      <div className={styles.contactWrapper}>
        <ContactCard
          image={sune}
          name="Adam Hebert"
          phone="073-1449331"
          email="adam@dalarorentreprenad.com"
        />
      </div>
    </div>
  );
};
export default Contact;
