import React from "react";
import styles from "./styles.module.scss";
import logo from "../../images/logo.png";

const Header = () => {
  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="Dala Rörservice Logo" />
    </div>
  );
};
export default Header;
